@import "../variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/functions";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/variables";
@import "../../../../node_modules/bootstrap-v4-rtl/scss/mixins";

body{
  background-color: #E4EBF2 !important;
  padding: 0;
  margin: 0;
  &.rtl {
    direction: rtl;
    text-align: right;
    .fa-chevron-left:before {
      content: "\f054"!important;
    }
  }
}

.error-page-page-head{
  background-color: #fff;
  box-shadow: 0 0 6px rgb(160, 183, 206);
  height: 66px;
  display: flex;
  align-items: center;
  color: #75799D;
  font-size: 16px;
  .container-fluid{
    display: flex;
    align-items: center;
    img{
      width: auto;
      height: 35px;
      margin: 0 15px;
    }
    span{
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
}

.error-page-content{
  height: calc(100vh - 66px);
  padding: 50px 0;
  display: flex;
  @include media-breakpoint-down(sm){
    height: auto;
  }
  .error-page-content-wrap{
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    .container{
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      .content-wrap{
        background: #F6F9FC;
        display: flex;
        height: 100%;
        align-items: center;
        overflow: hidden;
        position: relative;
        max-height: 600px;
        @include media-breakpoint-down(sm){
          max-height: unset;
        }
        &:after{
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          padding-bottom: 50%;
          background-color: #fff;
          background-image: url('../../imgs/error-bg.svg');
          background-position: center;
          background-size: contain;
          -webkit-clip-path: polygon(0 69%,-100% 100%,130% 100%);
          clip-path: polygon(0 69%,-100% 100%,130% 100%);
          bottom: 0;
          z-index: 0;
        }
        .content{
          padding: 25px;
          width: 100%;
          display: flex;
          position: relative;
          z-index: 1;
          justify-content: space-around;
          @include media-breakpoint-down(sm){
            flex-direction: column;
            padding: 15px 0;
          }
          .img{
            display: flex;
            &.img-1{
              @include media-breakpoint-down(lg){
                display: none;
              }
            }
          }
          .text{
            width: 270px;
            margin: 0 30px;
            display: flex;
            align-items: center;
            @include media-breakpoint-down(sm){
              order: 12;
              padding-top: 30px;
              width: auto;
            }
            &.lg{
              width: 300px;
              @include media-breakpoint-down(sm){
                width: auto;
              }
            }
            h4{
              font-weight: 700;
              font-size: 28px;
              color: #4E5381;
              margin-bottom: 20px;
            }
            p{
              font-size: 20px;
              line-height: 1.4;
              font-weight: 200;
              color: #75799D;
            }
            a{
              font-weight: 600;
              font-size: 24px;
              color: #00AEEF;
              line-height: 1.5;
              i{
                min-width: 25px;
              }
            }
          }
        }
      }
    }
  }
}
